import { gridLayoutPositions, topProximityFactor, leftProximityFactor, bottomProximityFactor, rightProximityFactor, gridLayoutTypes } from '../constants/gridLayout';
import { originX as originXOptions, originY as originYOptions } from '../constants/commonData';
import { layers as layerTypes } from '../constants/layers'

export const getLayoutPosition = (
  layoutDimension,
  contentDimension,
  layoutPosition,
  positionOffset,
  ignoreOrigin,
  ignoreProximityFactor
) => {
  let hOffset = positionOffset.hOffset * 0.01;
  let vOffset = positionOffset.vOffset * 0.01;
  let centerOffsetX = 0, centerOffsetY = 0, widthOffset = 0;

  let topProximity = topProximityFactor;
  let leftProximity = leftProximityFactor;
  let rightProximity = rightProximityFactor;
  let bottomProximity = bottomProximityFactor;

  if (ignoreProximityFactor) {
    topProximity = leftProximity = 0;
    rightProximity = bottomProximity = 1;
  }

  if (ignoreOrigin) {
    centerOffsetX = contentDimension.width / 2;
    centerOffsetY = contentDimension.height / 2;
    widthOffset = contentDimension.width;
  }

  switch (layoutPosition) {
    case gridLayoutPositions.topLeft:
      return {
        left: parseInt(Math.ceil((leftProximity + hOffset) * layoutDimension.width) + centerOffsetX),
        top: parseInt(Math.ceil((topProximity + vOffset) * layoutDimension.height) + centerOffsetY)
      };
    case gridLayoutPositions.topCentre: {
      let center = (layoutDimension.width / 2) - centerOffsetX;
      return {
        left: parseInt(Math.ceil(center + (center * 2 * hOffset)) + centerOffsetX),
        top: parseInt(Math.ceil((topProximity + vOffset) * layoutDimension.height) + centerOffsetY)
      }
    }
    case gridLayoutPositions.topRight:
      return {
        left: parseInt(Math.floor((layoutDimension.width * (rightProximity + hOffset)) - widthOffset) + centerOffsetX),
        top: parseInt(Math.floor((topProximity + vOffset) * layoutDimension.height) + centerOffsetY)
      }
    case gridLayoutPositions.middleLeft: {
      let center = layoutDimension.height / 2;
      return {
        left: parseInt(Math.ceil((leftProximity + hOffset) * layoutDimension.width) + centerOffsetX),
        top: parseInt(Math.ceil(center + (center * 2 * vOffset)))
      }
    }
    case gridLayoutPositions.middleCentre: {
      let hCenter = (layoutDimension.width / 2) - centerOffsetX;
      let vCenter = (layoutDimension.height / 2) - centerOffsetY;
      return {
        left: parseInt(Math.ceil(hCenter + (hCenter * 2 * hOffset)) + centerOffsetX),
        top: parseInt(Math.ceil(vCenter + (vCenter * 2 * vOffset)) + centerOffsetY)
      }
    }
    case gridLayoutPositions.middleRight: {
      let center = (layoutDimension.height / 2) - centerOffsetY;
      return {
        left: parseInt(Math.ceil((layoutDimension.width * (rightProximity + hOffset)) - widthOffset) + centerOffsetX),
        top: parseInt(Math.ceil(center + (center * 2 * vOffset)) + centerOffsetY)
      }
    }
    case gridLayoutPositions.bottomLeft:
      return {
        left: parseInt(Math.ceil((leftProximity + hOffset) * layoutDimension.width) + centerOffsetX),
        top: parseInt(Math.floor((layoutDimension.height * (bottomProximity + vOffset)) - centerOffsetY))
      }
    case gridLayoutPositions.bottomCentre: {
      let center = (layoutDimension.width / 2) - centerOffsetX;
      return {
        left: parseInt(Math.ceil(center + (center * 2 * hOffset)) + centerOffsetX),
        top: parseInt(Math.ceil((layoutDimension.height * (bottomProximity + vOffset)) - centerOffsetY))
      }
    }
    case gridLayoutPositions.bottomRight:
      return {
        left: parseInt(Math.floor((layoutDimension.width * (rightProximity + hOffset)) - centerOffsetX)),
        top: parseInt(Math.floor((layoutDimension.height * (bottomProximity + vOffset)) - centerOffsetY))
      }
  }
};

export const getLayoutType = function (width, height) {
  var aspectRatio = width / height;
  if (aspectRatio == 1) {
    return gridLayoutTypes.Square;
  }
  if(aspectRatio > 0.5 && aspectRatio < 2)
  {
    return gridLayoutTypes.Rectangle;
  }
  if (aspectRatio >= 2) {
    return gridLayoutTypes.Horizontal;
  }
  return gridLayoutTypes.Vertical;
}

export const getAdLayoutPosition = function (layoutType, width, height, fabricObject, contentWidth, contentHeight, hasOriginChanged) {
  let posX = fabricObject.left,
    posY = fabricObject.top,
    originX = fabricObject.originX,
    originY = fabricObject.originY,
    centerOffsetX = contentWidth / 2,
    centerOffsetY = contentHeight / 2;
  let top = posY - centerOffsetY,
    left = posX - centerOffsetX;
  if (hasOriginChanged) {
    centerOffsetY = centerOffsetX = 0;
    if (originX === originXOptions.right) {
      left = posX - contentWidth;
    } else if (originX === originXOptions.left){
      left = posX;
    }

    if (originY === originYOptions.bottom) {
      top = posY - contentHeight;
    } else if (originY === originYOptions.top) {
      top = posY;
    }
  }
  const bottom = top + contentHeight;
  const right = left + contentWidth;
  const centerY = top + (contentHeight / 2);
  const centerX = left + (contentWidth / 2);

  let topLeft = { sx: 0, sy: 0, dx: left, dy: top };
  let topCenter = { sx: Math.round(width / 2), sy: 0, dx: centerX, dy: top };
  let topRight = { sx: width, sy: 0, dx: right, dy: top };
  let middleLeft = { sx: 0, sy: Math.round(height / 2), dx: left, dy: centerY };
  let middleCenter = { sx: Math.round(width / 2), sy: Math.round(height / 2), dx: centerX, dy: centerY };
  let middleRight = { sx: width, sy: Math.round(height / 2), dx: right, dy: centerY };
  let bottomLeft = { sx: 0, sy: height, dx: left, dy: bottom };
  let bottomCenter = { sx: Math.round(width / 2), sy: height, dx: centerX, dy: bottom };
  let bottomRight = { sx: width, sy: height, dx: right, dy: bottom };
  let points = [topLeft, topCenter, topRight, middleLeft, middleCenter, middleRight, bottomLeft, bottomCenter, bottomRight];
  let distances = points.map(point => Math.sqrt(Math.pow(point.sx - point.dx, 2) + Math.pow(point.sy - point.dy, 2)));
  let minIndex = distances.indexOf(Math.min.apply(Math, distances));
  let positionIndex;
  let hOffset;
  let vOffset;
  let center;
  switch(minIndex) {
    case 0:
      positionIndex = gridLayoutPositions.topLeft;
      hOffset = (((posX - centerOffsetX) / width) - leftProximityFactor) * 100;
      vOffset = (((posY - centerOffsetY) / height) - topProximityFactor) * 100;
      break;
    case 1:
      positionIndex = gridLayoutPositions.topCentre;
      center = (width / 2) - centerOffsetX;
      hOffset = ((posX - center - centerOffsetX) / (center * 2)) * 100;
      vOffset = ((posY - centerOffsetY) / height - topProximityFactor) * 100;
      break;
    case 2:
      positionIndex = gridLayoutPositions.topRight;
      hOffset = (((posX + centerOffsetX) / width) - rightProximityFactor) * 100;
      vOffset = ((posY - centerOffsetY) / height - topProximityFactor) * 100;
      break;
    case 3:
      positionIndex = gridLayoutPositions.middleLeft;
      center = height / 2;
      hOffset = ((posX - centerOffsetX) / width - leftProximityFactor) * 100;
      vOffset = ((posY - center) / (center * 2)) * 100;
      break;
    case 4:
      positionIndex = gridLayoutPositions.middleCentre;
      let hCenter = (width / 2) - centerOffsetX;
      let vCenter = (height / 2) - centerOffsetY;
      hOffset = ((posX - hCenter - centerOffsetX) / (hCenter * 2)) * 100;
      vOffset = ((posY - vCenter - centerOffsetY) / (vCenter * 2)) * 100 ;
      break;
    case 5:
      positionIndex = gridLayoutPositions.middleRight;
      center = (height / 2) - centerOffsetY;
      hOffset = ((posX + centerOffsetX) / width - rightProximityFactor) * 100;
      vOffset = ((posY - center - centerOffsetY) / (center * 2)) * 100;
      break;
    case 6:
      positionIndex = gridLayoutPositions.bottomLeft;
      hOffset = ((posX - centerOffsetX) / width - leftProximityFactor) * 100;
      vOffset = ((posY + centerOffsetY) / height - bottomProximityFactor) * 100;
      break;
    case 7:
      positionIndex = gridLayoutPositions.bottomCentre;
      center = (width / 2) - centerOffsetX;
      hOffset = ((posX - center - centerOffsetX) / (center * 2)) * 100;
      vOffset = ((posY + centerOffsetY) / height - bottomProximityFactor) * 100;
      break;
    case 8:
      positionIndex = gridLayoutPositions.bottomRight;
      hOffset = ((posX + centerOffsetX) / width - rightProximityFactor) * 100;
      vOffset = ((posY + centerOffsetY) / height - bottomProximityFactor) * 100;
      break;
  }
  return {
    layoutType,
    positionIndex,
    positionOffset: {
      hOffset,
      vOffset
    }
  };
}

export const setLayoutPosition = (ad, fabricObject, data, layerInCanvas = null, positionDifference = null) => {
  if(positionDifference){
    fabricObject.left += positionDifference.left;
    fabricObject.top += positionDifference.top;
  } else {
    var adLayoutPosition = data.adLayoutPositions.find(layoutPosition => layoutPosition.layoutType == ad.layoutType);
    if (adLayoutPosition) {
      var layoutPosition = getLayoutPosition(
        { width: ad.width, height: ad.height },
        { width: fabricObject.getScaledWidth(), height: fabricObject.getScaledHeight() },
        adLayoutPosition.positionIndex,
        adLayoutPosition.positionOffset,
        (data.styles && data.styles.hasOriginChanged != null) ? !data.styles.hasOriginChanged : true
      );
      fabricObject.set({ left: layoutPosition.left });
      fabricObject.set({ top: layoutPosition.top });
    }
  }
  if(layerInCanvas && layerInCanvas.absoluteProps && !layerInCanvas.absoluteProps.applyAbsolutePosition) {
    layerInCanvas.absoluteProps.positionLeft = fabricObject.left;
    layerInCanvas.absoluteProps.positionTop = fabricObject.top;
  }
}

export const updateGridLayoutPosition = function(layerData, ad, fabricObject, layerType) {
  let layoutPosition = layerData.adLayoutPositions.find(layoutPosition => layoutPosition.layoutType == ad.layoutType);
  const hasOriginChanged = layerType === layerTypes.button ? layerData.hasOriginChanged : layerData.styles.hasOriginChanged
  Object.assign(layoutPosition,
    getAdLayoutPosition(
      ad.layoutType,
      ad.width,
      ad.height,
      fabricObject,
      fabricObject.getScaledWidth(),
      fabricObject.getScaledHeight(),
      hasOriginChanged
    ));
}
const TWEEN = require('@tweenjs/tween.js');
import { TWEEN_START_VALUE, TWEEN_END_VALUE, MIN_TWEEN_DURATION } from '../constants/animation';

// AY TODO: Most of the function calls are within same file - except in AnimationPreview.vue which uses this to preview the animation
/**
 * Sets up a tween which modifies the {targetObject} fabric object's properties as specified in {originalObject}
 * Adds this tween to the timeline. Used externally in {@link AnimationPreview.vue}
 * @param {Number} startDelay - the delay after which the tween will start
 * @param {fabric.Object} targetObject - the object whose properties will be modified
 * @param {TWEEN.Group} timeline - the timeline group to which to add the tween
 * @param {*} originalObject - the properties to modify
 * @returns TWEEN.Tween
 */
function changeFabricObject(startDelay, targetObject, timeline, originalObject) {
  // We will always tween from TWEEN_START_VALUE=0 to TWEEN_END_VALUE=1
  // The tweened value will then be applied to the object property accordingly.
  // For example: To tween the top/left, we basically do (object.top*tweenedValue)
  var o = { value: TWEEN_START_VALUE };
  return new TWEEN.Tween(o, timeline)
    .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
    .onStart(function () {
      // On starting the tween, reset the object
      Object.assign(targetObject, JSON.parse(JSON.stringify(originalObject)));
      targetObject.set({ objectCaching: false });
    })
    .delay(startDelay);
}

function hideFabricObject(duration, targetObject, timeline) {
  return new TWEEN.Tween({ value: TWEEN_START_VALUE }, timeline)
    .to({ value: TWEEN_END_VALUE }, duration)
    .onStart(function () {
      Object.assign(targetObject, { opacity: 0 });
    })
    .delay(0);
}

export const tweenGenerator = {
  changeFabricObject,
  hideFabricObject
}
// Set node flag
global.isNode = () => false;

import AxiosService from '@deltax/api-client';

const getPortalDomain = () => {
  if(typeof module.hot !== 'undefined'){
    return 'roimatic.adbox.pro'
  }
  var queryParameters = new URLSearchParams(window.location.search);
  var portalDomain = queryParameters.get('portalDomain');

  if(portalDomain){
    return portalDomain;
  }

  return window.location.host;
}

/*
  Expectation is that the process.env should have the right client app id

  ErrorHandler is passed as null
    Without ErrorHandler the DeltaX/Api-Client package will reject the promise
    Then we can intercept the response and handle the error in the App where this submodule will be used
*/

const axiosService =  new AxiosService(process.env.VUE_APP_API_URL, process.env.VUE_APP_DELTAX_CLIENT_APP_ID, getPortalDomain(), null);

export default axiosService.axiosInstance;

export const apiMethods = axiosService.getApiMethods();